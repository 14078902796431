import React from "react";
import "./CataloguePage.css";

const CataloguePage = () => {
  return (
    <div>
      <div className="cataloguePage">
        <h2>Catalogue</h2>

        <p>
          <iframe
            title="Catalogue content from Example"
            allowFullScreen="allowfullscreen"
            className="fp-iframe"
            style={{
              border: "1px solid lightgray",
              width: "100%",
              height: "600px",
            }}
            src="https://heyzine.com/flip-book/e38bb5d703.html"
          ></iframe>
        </p>
      </div>
    </div>
  );
};

export default CataloguePage;
